<template>
  <div class="faltanDatosContainer">
    <div class="faltanDatodTitle title">
      {{ t("app.faltanDatos.tit") }}
    </div>

    <div class="faltanDatosText">
      <p>{{ t("app.faltanDatos.texto_2_1") }}</p>
      <p class="faltanDatosText_2" v-if="queda_tiempo_completar_registro">{{ t("app.faltanDatos.texto_2_2") }}</p>
    </div>

    <div class="faltanDatosCompletarBtn">
      <btnAction
        :textBtn="t('app.comunes.btns.completarAhora')"
        @action="completarRegistro"
        colorBack="rojo"
        colorText="blco"
        :estadoBtn="estadoBtn"
      />
    </div>

    <div @click="otroMomento" class="falanDatosOtroMomento" v-if="queda_tiempo_completar_registro">
      {{ t("app.comunes.btns.otroMomento") }}
    </div>
    <div @click="abandonar" class="falanDatosOtroMomento" v-else>
      {{ t("app.comunes.btns.abandonar") }}
    </div>
  </div>
</template>

<script>
import { modalController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import btnAction from "../../components/generales/btnAction.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "faltanDatos",
  components: {
    btnAction,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["remitente", ]),

    estadoBtn() {
      let aux = "active";

      return aux;
    },
    queda_tiempo_completar_registro(){
      let aux = true;
      if(this.remitente.estado_registro == "2"){
        aux = false;
      }
      if(this.remitente.estado_registro == "3" && true){//implementar fecha límite de completar registro para procesos futuros de actualización de datos
        aux = false;
      }
      
      return aux;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),
    ...mapActions(["traer_ocupaciones", "traer_regiones"]),
    
    otroMomento() {
      modalController.dismiss({ dismissed: true, completarRegistro: false });
    },
    async completarRegistro() {
      await this.traer_ocupaciones();
      await this.traer_regiones("152");
      this.setshowSpinner(false);
      modalController.dismiss({ dismissed: true, completarRegistro: true });
    },
    abandonar(){
      modalController.dismiss({ dismissed: true, completarRegistro: false });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.faltanDatosContainer {
  /* background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15); */
  padding: 1.6875rem 1.5625rem 3.125rem 1.5625rem;
}
.faltanDatodTitle {
  color: var(--customA-801B1C);
  text-align: center;
}
.faltanDatosText {
  margin-top: 3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--customG-787878);
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.faltanDatosText p{
  width: fit-content;
}
.faltanDatosText_2 {
  padding-top: 0.8rem;
}
.faltanDatosCompletarBtn {
  margin-top: 3rem;
}
.falanDatosOtroMomento {
  margin-top: 3.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2rem;
  display: flex;
  justify-content: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--customG-787878);
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .faltanDatosBack {
    width: 60%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>