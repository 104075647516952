<template>
  <screen_width_cont>
    <div
      class="cont_300_basico ancho_acotado header_top"
      @click.stop="colapsarIdiomaSelect = true"
    >
      <div class="">
        <div class="idiomaSelectContainerLogin elemento_deshabilitado">
          <idiomaSelect
            :colapsarIdiomaSelect="colapsarIdiomaSelect"
            @stopCloseListaIdioma="colapsarIdiomaSelect = false"
          />
        </div>

        <div class="logoLoginUp">
          <img :src="logo" alt="" srcset="" />
        </div>

        <div class="logoLoginDown title">
          <p>{{ t("app.logIn.tit") }}</p>
        </div>

        <div class="logoLoginInputs">
          <div class="">
            <inputBox
              typeInput="rut"
              placeHolder="Rut"
              requerido="*"
              :rut="true"
              :error="errorRut"
              @dispatchChangeData="updateRut"
              :mensajeError="[
                t('configuracion.data.textos_errores.campoObligatorio'),
                t('configuracion.data.textos_errores.rut'),
              ]"
              :mensajeOk="{ mostrar: false, texto: '' }"
              @evaluarData="evaluarDataRut"
              :borrarDatos="borrarDatos"
              dataInicial=""
            />
          </div>

          <div class="logInRecoverPassCont">
            <span @click="recoverPassword">
              {{ t("app.comunes.btns.contrasena") }}
            </span>
          </div>

          <div class="">
            <inputBox
              typeInput="textP"
              :placeHolder="t('app.logIn.contrasena')"
              requerido="*"
              :rut="false"
              :error="errorPass"
              @dispatchChangeData="updatePass"
              :mensajeError="[
                t('configuracion.data.textos_errores.campoObligatorio'),
                '',
              ]"
              :mensajeOk="{ mostrar: false, texto: '' }"
              @evaluarData="evaluarDataPass"
              :borrarDatos="borrarDatos"
              dataInicial=""
            />
          </div>
        </div>

        <div class="btnsLogin">
          <div class="btnsLoginAction">
            <btnAction
              :textBtn="t('app.comunes.btns.login')"
              @action="logInLocal"
              :disabled="false"
              colorBack="rojo"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
            />
          </div>

          <div class="logInRegistroCont">
            <div>{{ t("app.logIn.registro") }}</div>
            <div @click="registrar" class="logInRegistroCont2">
              {{ t("app.comunes.btns.registro") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";

import { mapActions, mapMutations, mapState } from "vuex";
import faltanDatosModal from "@/views/modales/faltanDatos.vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import idiomaSelect from "../components/logIn/idiomaSelect.vue";
import inputBox from "../components/generales/inputBox.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "Home",
  components: {
    btnAction,
    idiomaSelect,
    inputBox,
    screen_width_cont,
  },
  data() {
    return {
      txtRut: "",
      txtContrasena: "",
      title: "",
      subTitle: "",
      buttons: [],
      modalActivo: false,
      logo: require("../assets/imgs/logo.svg"),
      colapsarIdiomaSelect: false,
      recordarme: false,
      errorRut: false,
      errorPass: false,
      borrarDatos: false,
      call_login_token: false,
      directo_enviar: false,
      en_vista: false,
      redirect_to: {
        redirect: false,
        to: "",
      },
    };
  },
  computed: {
    ...mapState([
      "remitente",
      "faltanDatos",
      "respLogIn",
      "idiomaPreferencia",
      "transaccion",
      "configuracion",
      "widthScreen",
      "obj_gf_base",
      "resp_create_config_others",
      "heigthScreen",
      "datos_consultados",
      "monto_inicio_BD",
      "pais_destino_inicial_BD",
      "resp_validar_codigo_registro",
    ]),
    respLogInReact() {
      return this.respLogIn;
    },
    estadoBtn() {
      let aux = "inactive";
      if (this.txtRut != "" && this.txtContrasena != "") {
        aux = "active";
      }
      return aux;
    },
    data_inicial() {
      let aux = false;
      if (this.obj_gf_base.paises && this.obj_gf_base.data_base) {
        aux = true;
      }
      return aux;
    },
    queda_tiempo_completar_registro() {
      let aux = true;
      if (this.remitente.estado_registro == "2") {
        aux = false;
      }
      if (this.remitente.estado_registro == "3" && true) {
        //implementar fecha límite de completar registro para procesos futuros de actualización de datos
        aux = false;
      }

      return aux;
    },
  },
  methods: {
    ...mapActions([
      "endprocess",
      "changeStep",
      "limpiarTransaccion",
      "dibujarPaso",
      "logIn",
      "validarString",
      "validarRut",
      "logIn_con_token",
      // "getCookie",
      "limpiar_datos_web_enviar",
      "signOff",
      "callFloidVerificaRut",
      "traer_ocupaciones",
      "traer_regiones",
    ]),
    ...mapMutations([
      "updateRemitente",
      "updateSession",
      "updateDesde",
      "updatePasoActual",
      "updateSessionConfirm",
      "updateBloquearNavegacion",
      "updateProcesoGeneral",
      "set_accept_term_cond",
      "set_accept_poli_priv",
      "set_accept_consulta_reg_civil",
      "updateTransaccion",
      "set_pre_values_cotizador",
      "setshowSpinner",
      "limpiarRemitente",
      "set_data_mostrar_error",
      "updateMenuSpDesde_menu_sp",
      "set_nav_stack",
    ]),

    changeStateChekLocal(estado) {
      this.recordarme = estado;
    },

    updateRut(data) {
      this.errorRut = false;
      this.txtRut = data;
    },
    updatePass(data) {
      this.txtContrasena = data;
    },
    async evaluarDataRut(data) {
      if (
        this.configuracion.data &&
        Object.keys(this.configuracion.data).length > 0
      ) {
        let index = this.configuracion.reglasFormularioRemitente.findIndex(
          (regla) => regla[4] == "numero_documento"
        );
        let errores = await this.validarString({
          string: data,
          validaciones: this.configuracion.reglasFormularioRemitente[index][8],
        });
        if (errores.length > 0) {
          this.errorRut = true;
        } else {
          this.errorRut = false;
        }
      } else {
        this.set_data_mostrar_error({
          title: "Error",
          text: "Problema de comunicación con el servidor.",
        });
      }
    },
    evaluarDataPass(data) {
      if (data == "") {
        this.errorPass = true;
      } else {
        this.errorPass = false;
      }
    },
    async logInLocal() {
      if (
        this.configuracion.data &&
        Object.keys(this.configuracion.data).length > 0
      ) {
        this.errorPass = false;
        this.errorRut = false;

        let usuario = this.txtRut.trim();
        let contrasena = this.txtContrasena.trim();

        if (usuario.length <= 8 || usuario.length > 10) {
          this.errorRut = true;
        }
        if (contrasena.length <= 4) {
          this.errorPass = true;
        }
        let index = this.configuracion.reglasFormularioRemitente.findIndex(
          (regla) => regla[4] == "numero_documento"
        );
        let errores = await this.validarString({
          string: usuario,
          validaciones: this.configuracion.reglasFormularioRemitente[index][8],
        });

        if (index && errores.length > 0) {
          this.errorRut = true;
        }

        let variable = usuario.replaceAll(".", "");
        variable = variable.replaceAll("-", "");
        let numChar = variable.length;
        let rut = variable.substring(0, numChar - 1);
        let digito = variable.substring(numChar - 1);
        variable = `${rut}-${digito}`;
        let checkRut = await this.validarRut(variable);
        if (!checkRut) {
          this.errorRut = true;
        }

        if (!this.errorRut && !this.errorPass) {
          this.logIn({ usuario: usuario, contrasena: contrasena });
        } else {
          this.setshowSpinner(false);
        }
      } else {
        this.setshowSpinner(false);
        this.set_data_mostrar_error({
          title: "Error",
          text: "Problema de comunicación con el servidor.",
        });
      }
    },

    async registrar() {
      // this.set_accept_term_cond(false);
      // this.set_accept_poli_priv(false);
      // this.set_accept_consulta_reg_civil(false);
      // this.$router.push({ name: "registroDisclaimer", replace: true });
      this.$router.push({ name: "registro", replace: true });
    },
    async activarModalFaltanDatos() {
      let cssClass = ["myModalAbandonar"];
      if (this.widthScreen < 400) {
        cssClass.push("myModalAbandonar_ancho_90");
      } else if (this.widthScreen < 600) {
        cssClass.push("myModalAbandonar_ancho_80");
      } else if (this.widthScreen < 800) {
        cssClass.push("myModalAbandonar_ancho_70");
      } else {
        cssClass.push("myModalAbandonar_ancho_50");
      }

      if (this.heigthScreen < 600) {
        cssClass.push("myModalAbandonar_alto_90");
      } else if (this.heigthScreen < 700) {
        cssClass.push("myModalAbandonar_alto_80");
      } else if (this.heigthScreen < 900) {
        if (this.widthScreen < 400) {
          cssClass.push("myModalAbandonar_alto_70");
        } else {
          cssClass.push("myModalAbandonar_alto_50");
        }
      } else if (this.heigthScreen < 1200) {
        cssClass.push("myModalAbandonar_alto_60");
      } else {
        cssClass.push("myModalAbandonar_alto_60");
      }

      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: faltanDatosModal,
        animated: true,
        backdropDismiss: false,
        cssClass: cssClass,
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);

        if (data.data.completarRegistro) {
          // if (
          //   this.faltanDatos == true &&
          //   this.remitente.cliente_nuevo == "0" &&
          //   this.remitente.estado_registro == "2"
          // ) {
          //   // this.mostrarRegistroDisclaimer();
          // } else if (
          //   this.faltanDatos == true &&
          //   this.remitente.cliente_nuevo == "1" &&
          //   this.remitente.estado_registro == "2"
          // ) {
          //   // this.setshowSpinner(true);
          //   // this.callFloidVerificaRut(this.remitente.numero_documento);
          // } else {
          //   this.$router.push({ name: "registroFormEdit", replace: true });
          // }
          this.$router.push({ name: "registroFormEdit", replace: true });
        } else {
          if (!this.queda_tiempo_completar_registro) {
            this.signOff();
          } else {
            this.$router.push({ name: "home", replace: true });
          }
        }
      });

      return modal.present();
    },
    async presentAlert() {
      let alert = await alertController.create({
        header: this.title,
        subHeader: this.subTitle,
        buttons: this.buttons,
        backdropDismiss: false,
      });
      await alert.present();
    },

    async recoverPassword() {
      this.$router.push({ name: "recoverPass", replace: true });
    },
  },
  watch: {
    faltanDatos() {
      this.setshowSpinner(false);
      if (this.faltanDatos) {
        this.txtRut = "";
        this.txtContrasena = "";
        this.borrarDatos = !this.borrarDatos;
        this.setshowSpinner(false);
        // this.activarModalFaltanDatos();
        if (
          this.faltanDatos == true &&
          this.remitente.cliente_nuevo == "0" &&
          this.remitente.estado_registro == "2"
        ) {
          this.$router.push({ name: "registroDisclaimer", replace: true });
        } else if (
          this.faltanDatos == true &&
          this.remitente.cliente_nuevo == "1" &&
          this.remitente.estado_registro == "2"
        ) {
          this.$router.push({ name: "registroDisclaimer", replace: true });
        } else if (
          this.faltanDatos == true &&
          this.remitente.cliente_nuevo == "1" &&
          this.remitente.estado_registro == "3"
        ) {
          this.activarModalFaltanDatos();
        } else {
          this.activarModalFaltanDatos();
        }
      }
    },
    async respLogInReact() {
      if (this.respLogInReact.resp) {
        this.txtRut = "";
        this.txtContrasena = "";
        this.borrarDatos = !this.borrarDatos;
        this.setshowSpinner(false);
        if (this.directo_enviar) {
          this.$router.push({ name: "cotizador", replace: true });
        } else {
          // console.log(this.redirect_to);
          if (this.redirect_to.redirect) {
            if (this.redirect_to.to == "misDatos") {
              this.setshowSpinner(true);
              await this.traer_ocupaciones();
              await this.traer_regiones("152");
              this.updateMenuSpDesde_menu_sp(true);

              this.set_nav_stack({
                desde: ["home"],
                menuSp: true,
              });
              this.setshowSpinner(false);
              this.$router.push({ name: "misDatos", replace: true });
            } else if (this.redirect_to.to == "destinatarios") {
              this.updateMenuSpDesde_menu_sp(true);
              this.set_nav_stack({ desde: ["home"], menuSp: true });
              this.$router.push({ name: "destinatarios", replace: true });
            } else if (this.redirect_to.to == "misTransac") {
              this.updateMenuSpDesde_menu_sp(true);
              this.set_nav_stack({ desde: ["home"], menuSp: true });
              this.$router.push({ name: "misTransac", replace: true });
            }
          } else {
            this.$router.push({ name: "home", replace: true });
          }
        }

        this.setshowSpinner(false);
      } else {
        this.setshowSpinner(false);
        if (this.respLogInReact.msg.length > 0) {
          this.title = `${this.t("app.logIn.error")}`;
          this.subTitle = this.t(
            `configuracion.errores_srv.Cliente.${this.respLogInReact.msg}`
          );
          this.buttons = ["Ok"];
          this.presentAlert();
        }
      }
    },
    resp_create_config_others() {
      if (
        this.resp_create_config_others.resp &&
        this.call_login_token &&
        this.data_inicial
      ) {
        this.logIn_con_token(this.remitente.token);
      }
    },
    call_login_token() {
      if (
        this.resp_create_config_others.resp &&
        this.call_login_token &&
        this.data_inicial
      ) {
        this.logIn_con_token(this.remitente.token);
      }
    },
    resp_validar_codigo_registro() {
      if (this.resp_validar_codigo_registro.status && this.en_vista) {
        this.setshowSpinner(false);
        if (this.resp_validar_codigo_registro.resp) {
          this.$router.push({ name: "registroFormInicial", replace: true });
        }
      }
    },
  },
  created() {},
  mounted() {},
  async ionViewWillEnter() {
    this.en_vista = true;
    this.setshowSpinner(false);
    this.limpiarRemitente({});
    await this.limpiarTransaccion("logIn");

    this.updateDesde("logIn");
    this.updateProcesoGeneral("logIn");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redir");

    if (redirect && redirect == "registro") {
      this.$router.push({ name: "registro", replace: true });
    }
    // else
    // if (redirect && redirect == "misDatos") {
    //   console.log("redirecto to", redirect);
    //   //marcar ruta back
    //   this.redirect_to = {
    //     redirect: true,
    //     to: "misDatos"
    //   };
    // } else if (redirect && redirect == "destinatarios") {
    //   //marcar ruta back
    //   this.$router.push({ name: "destinatarios", replace: true });
    // } else if (redirect && redirect == "misTransac") {
    //   //marcar ruta back
    //   this.$router.push({ name: "misTransac", replace: true });
    // }
    else {
      let x = document.cookie;
      let galletas = x.split(";");

      galletas.forEach((element) => {
        let item = element.split("=");
        item[0] = item[0].trim();
        if (item[0] == "gf_token") {
          if (item[1].length > 0) {
            this.updateRemitente({ token: item[1] });

            this.call_login_token = true;
            this.setshowSpinner(true);

            if (redirect && redirect == "misDatos") {
              console.log("redirecto to", redirect);
              //marcar ruta back
              this.redirect_to = {
                redirect: true,
                to: "misDatos",
              };
            } else if (redirect && redirect == "destinatarios") {
              this.redirect_to = {
                redirect: true,
                to: "destinatarios",
              };
            } else if (redirect && redirect == "misTransac") {
              this.redirect_to = {
                redirect: true,
                to: "misTransac",
              };
            }
          }
        }
        if (item[0] == "gf_dest") {
          if (item[1] != "") {
            this.set_pre_values_cotizador({
              pais_destino_inicial: item[1],
            });
            this.limpiar_datos_web_enviar("gf_dest");
          } else {
            this.set_pre_values_cotizador({
              pais_destino_inicial: this.pais_destino_inicial_BD,
            });
          }
        }
        if (item[0] == "gf_cant") {
          if (item[1].length > 0) {
            this.set_pre_values_cotizador({
              monto_inicio: item[1],
            });
            this.limpiar_datos_web_enviar("gf_cant");
          } else {
            this.set_pre_values_cotizador({
              monto_inicio: this.monto_inicio_BD,
            });
          }
        }
      });
    }

    if (redirect && redirect == "enviar") {
      this.directo_enviar = true;
    }
  },
  ionViewWillLeave() {
    this.txtRut = "";
    this.txtContrasena = "";
    this.borrarDatos = !this.borrarDatos;
    this.en_vista = false;
  },
  beforeUnmount() {},
};
</script>

<style >
.idiomaSelectContainerLogin {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
}

.logoLoginUp {
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
}

.logoLoginUp img {
  width: 10.7rem;
}

.logoLoginDown {
  margin: 1rem auto 0% auto;
  display: flex;
  justify-content: center;
}

.logoLoginDown p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  letter-spacing: 1px;
  color: var(--customA-801B1C);
}

.logoLoginInputs {
  margin-top: 2rem;
}

.logInRecoverPassCont {
  margin-top: 2rem;
  color: var(--customC-614AD3);
  font-size: 0.9375rem;
  font-weight: 400;
  text-align: right;
  text-decoration-line: underline;
  font-style: italic;
}

.logInRecoverPassCont span {
  margin: 0;
  cursor: pointer;
}

.btnsLogin {
  text-align: center;
  padding-bottom: 2rem;
}

.btnsLogin .btnsLoginAction {
  margin-top: 3.4rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.recordarmeCont {
  margin-bottom: 1rem;
  text-align: left;
}

.recordarmeContLabel {
  color: var(--customG-787878);
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 13px;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.logInRegistroCont {
  margin-top: 3.4rem;
  color: var(--customH-4C4C4C);
  font-style: italic;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;
}

.logInRegistroCont2 {
  margin-top: 0.6rem;
  font-weight: 500;
  font-size: 1rem;
  color: var(--customC-614AD3);
  cursor: pointer;
  text-decoration: underline;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .logoLoginDown {
    padding-top: 4rem;
  }
}
</style>