<template>
  <div>
    <!-- <div class="idiomaSelect" @click.stop="listaIdiomasTogle"> -->
      <div class="idiomaSelect" @click.stop="presentAlert_i18n">
      <span> {{ idiomaPreferencia.cod.toUpperCase() }}</span>
      <img :src="globIdioma" alt="" srcset="" class="globIdioma" />
    </div>
    <div class="desplegable" v-if="desplegable">
      <div v-for="(idioma, index) in configuracion.data.auxiliares.idiomas[0]" :key="index" class="my-0"
        @click="cambiarIdioma(idioma)">
        <div class="idiomaOption" v-if="idioma[2] != locale">{{ idioma[1].toUpperCase() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { alertController } from "@ionic/vue";
import { mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  props: {
    colapsarIdiomaSelect: { Type: Boolean },
  },
  data() {
    return {
      globIdioma: require("../../assets/imgs/globIdioma.svg"),
      desplegable: false,
    };
  },
  computed: {
    ...mapState(["idiomaPreferencia", "configuracion"]),
  },
  methods: {
    ...mapMutations(["updateIdiomaPreferencia"]),

    
    async presentAlert_i18n() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader: this.t("app.logIn.i18n_proximamente"),
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },
    listaIdiomasTogle() {
      this.desplegable = !this.desplegable;
    },
    cambiarIdioma(idiomaSelected) {
      console.log(idiomaSelected);
      let selectedIdiomaObj =
        this.configuracion.data.auxiliares.idiomas[0].find((idioma) => {
          return idioma[0] == idiomaSelected[0];
        });
      this.updateIdiomaPreferencia({
        val: idiomaSelected[0],
        text: selectedIdiomaObj[1],
        cod: selectedIdiomaObj[2],
      });
      this.locale = selectedIdiomaObj[2];
      localStorage.setItem("idiomaPreferencia", idiomaSelected[0]);
    },
  },
  watch: {
    colapsarIdiomaSelect() {

      if (this.colapsarIdiomaSelect) {
        this.desplegable = false;
        this.$emit("stopCloseListaIdioma");
      }
    },
  },
};
</script>

<style >
.idiomaSelect {
  color: var(--customG-787878);
  display: flex;
  align-content: center;
  align-items: center;
}

.globIdioma {
  width: 1.25rem;
}

.idiomaSelect span {
  font-weight: 500;
  font-size: 1rem;
  padding-right: 1.2rem;
  line-height: 1rem;
  cursor: pointer;
  letter-spacing: 1px;
  /* font-style: italic; */
}

.idiomaSelect img {
  width: 1.5rem;
  cursor: pointer;
}

.desplegable {
  z-index: 100;
  position: absolute;
  top: 1.2rem;
  right: 1.8rem;
  padding: 0.4rem 1rem;
  border-radius: 3px;
  background-color: var(--customD-ECECEC);
  border-radius: 10px;
  color: var(--customG-787878);
  font-size: 0.875rem;
  font-weight: 400;

}

.desplegable div {
  display: flex;
  justify-content: center;
}

.idiomaOption {
  cursor: pointer;
  padding: 0.5rem 0;
  font-weight: 400;
  font-size: 0.875rem;
  /* padding-right: 0.875rem; */
  line-height: 1rem;
  cursor: pointer;
  letter-spacing: 1px;
  font-style: italic;
}
</style>